import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from 'src/app/shared/enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translateService: TranslateService) {}

  public setDefaultLanguage(): void {
    const userLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE);
    let defaultLanguage = '';
    if (userLanguage != null) {
      defaultLanguage = userLanguage;
    } else {
      const browserLanguage = navigator.language;
      defaultLanguage = 'en';
      if (browserLanguage.startsWith('tr')) {
        defaultLanguage = 'tr';
      }
      if (browserLanguage.startsWith('de')) {
        defaultLanguage = 'de';
      }

      localStorage.setItem(
        LOCAL_STORAGE_KEYS.BROWSER_LANGUAGE,
        defaultLanguage
      );
      this.translateService.setDefaultLang(defaultLanguage);
    }
  }
}
