import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from 'src/app/shared/enums';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
  lang: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private langIds: any = [];

  constructor(private translate: TranslateService) {
    this.translate.addLangs(['en', 'tr']);
    this.translate.setDefaultLang('en');
  }

  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      this.translate.setTranslation(locale.lang, locale.data, true);
      this.langIds.push(locale.lang);
    });

    this.translate.addLangs(this.langIds);
  }

  setLanguage(lang: any): void {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
    }
  }

  getSelectedLanguage(): any {
    const userLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE);
    const browserLanguage = localStorage.getItem(
      LOCAL_STORAGE_KEYS.BROWSER_LANGUAGE
    );
    const selectedLang =
      (userLanguage != null ? userLanguage : browserLanguage) ||
      this.translate.getDefaultLang();

    return selectedLang;
  }

  getLangList(): any {
    return this.translate.langs;
  }
}
