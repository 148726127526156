// GERMANY
export const locale = {
  lang: 'de',
  data: {
    CONTACT_INFORMATION: 'Kontaktinformation',
    FIRST_NAME: 'Vorname',
    LAST_NAME: 'Last name',
    PHONE_NUMBER: 'Phone number',
    EMAIL: 'Email',
    SHIPPING_ADDRESS: 'Shipping address',
    ADDRESS: 'Address',
    HOUSE_NUMBER: ' Add a house number if you have one',
    CITY: 'City',
    REGION: 'Region',
    POSTAL_CODE: 'Postal code',
    CONTINUE_TO_PAYMENT: 'Continue to payment',
    OPEN_WEBSITE: 'Opens external website in a new window.',
    OPEN_WINDOW: 'Opens in a new window.',
    OPEN_EXTERNAL_WEBSITE: 'Opens external website.',
    CUSTOMER_INFORMATION: 'Customer information - Liva Test - Checkout',
    ORDER_SUMMERY: 'Order summary',
    SHIPPING_CART: 'Shopping cart',
    PRODUCT_IMAGE: 'Product image',
    DESCRIPTION: 'Description',
    QUANTITY: 'Quantity',
    PRICE: 'Price',
    SCROLL_FOR_MORE_ITEMS: 'Scroll for more items',
    COST_SUMMERY: ' Cost summary',
    TOTAL: 'Total',
    PROD_SERVICES_TOTAL: 'prod/services total',
    UPDATE_TOTAL_PRICE: 'Updated total price',
    APARTMENT_SUIT: 'Apartment, suite, etc. (optional)',
    COUNTRY_REGION: 'Country/region',
    CHANGE: 'Change',
    CONTACT: 'Contact',
    SHIP_TO: 'Ship to',
    PAYMENT: 'Payment',
    CHOOSE_PAYMENT_METHOD: 'Choose a payment method',
    COMPLETE_PAYMENT: 'Complete Payment',
    ALL_TRANSACTIONS_ARE_SECURE: 'All transactions are secure and encrypted.',
    FULFILLED_SUCCESSFULLY: 'Request fulfilled successfully',
    REQUEST_FAILED: 'Unfortunately request failed',
    ERROR: 'Error',
    SUCCESS: 'Success',
    MIN_LENGTH_ERROR: 'The field should have at least {{minlength}} symbols',
    MAX_LENGTH_ERROR: 'The field should have maximum of {{maxlength}} symbols',
    FIELD_REQUIRED: '{{field}} is required',
    FIELD_IS_NOT_VALID: '{{field}} is not valid',
    SKIP_TO_CONTENT: 'Skip to content',
    INFORMATION: 'Information',
    SALE_PRICE: 'Sale price',
    ALL_RIGHT_RESERVED: 'All rights reserved',
    CREDIT_CARD: 'Credit card',
    PAY_WITH: 'Pay with',
    CASH_ON_DELIVERY: 'Cash on Delivery (COD)',
    STORE: 'Store',
    SHOW_ORDER_SUMMARY: 'Show order summary',
    HIDE_ORDER_SUMMARY: 'Hide order summary',
    CARD_NUMBER: 'Card Number',
    NAME_ON_CARD: 'Name on card',
    EXPIRATION_DATE: 'Expiration date',
    EXPIRATION_DATE_WITH_INPUT_STYLE: 'Expiration date (MM / YY)',
    SECURITY_CODE: 'Security code',
    MONTH: 'Month',
    YEAR: 'Year',
    ERROR_404: 'OOPS! Something went wrong here',
    ITS_ORDERED: "It's Ordered!",
    THANKS_FOR_ORDER: 'Thanks for your order, we hope you enjoyed',
    SHOPPING_WITH_US: 'shopping with us',
    ORDER_NUMBER: 'Order Number',
    PAYMENT_FAILED: 'Payment failed',
    PAYMENT_FAILED_DESCRIPTION: 'Your payment method was decline',
    CODE: 'Code',
    INVALID_CVC: 'Your cvc is invalid',
    INVALID_CVC2: 'Your cvc2 is invalid',
    INSUFFICIENT_FUNDS: 'Your funds is not sufficient for the transaction',
    EXPIRED_CARD: 'Your card is expired',
    GENERAL_PAYMENT_ERROR: 'An error occurred while processing payment',
    PAYMENT_NOT_COMPLETED:
      "Unfortunately, your payment operation didn't complete",
    YOUR_CARD_RESTRICTED:
      'Your card is restricted for online payments. You may enable online payments for  your card by contacting your bank',
    MAX_STOCK_EXCEEDED:
      "One of the items count in your basket is less than it's count in stock",
    PAYMENT_CONFIGURATION_NOT_SET:
      'Payment configuration not set in the seller panel',
    INVALID_TRANSACTION: 'Your transaction is invalid',
    OPERATION_NOT_APPROVED: 'Operation not approved',
    PAYMENT_REQUEST_HAS_NOT_BEEN_PASSED_FRAUD_CHECK:
      'Payment request has not passed Fraud check',
    OPERATION_NOT_PERMITTED_TO_CARD_HOLDER:
      'Operation not permitted to card holder',
    OPERATION_NOT_PERMITTED_TO_TERMINAL: 'Operation not permitted to terminal',
    FRAUD_SUSPECT: 'Fraud suspect',
    ERROR_PROCESSING_PAYMENT: 'An error occurred while processing payment',
    SYSTEM_ERROR: 'System error occurred',
    FORM:"Het formulier"
  },
};
