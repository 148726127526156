import { HttpClient, HttpHeaders } from '@angular/common/http';

import { END_POINTS_URL } from 'src/app/shared/constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaymentSettingService {
  constructor(private http: HttpClient) {}

  getPaymentInfo(channelId: string) {
    const URL = `${END_POINTS_URL.V2.PAYMENT_SETTINGS}/extended`;
    const Options = {
      headers: new HttpHeaders({
        'alias-Key': channelId,
      }),
    };
    return this.http.get(URL, Options);
  }
}
