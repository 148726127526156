import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { END_POINTS_URL } from 'src/app/shared/constants';
import { IOrderByShopilinkModel } from '../models/order-by-shopilink.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShopilinkService {
  constructor(private http: HttpClient) {}

  insertOrderWithShopilink(
    channelId: string,
    payload: IOrderByShopilinkModel
  ): Observable<any> {
    const Options = {
      headers: new HttpHeaders({
        'alias-Key': channelId,
        'Accept-Language': 'en',
      }),
    };
    const URL = `${END_POINTS_URL.V2.ORDERS_URL}/shopilink`;
    return this.http.post(URL, payload, Options);
  }

  getShopilinkInformationBySlug(slug: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SHOPILINKS}/getSingleBySlug/${slug}`;
    return this.http.get(URL);
  }
}
