// Turkey
export const locale = {
  lang: 'tr',
  data: {
    CONTACT_INFORMATION: 'İletişim bilgileri',
    FIRST_NAME: 'Ad',
    LAST_NAME: 'Soyadı',
    PHONE_NUMBER: 'Telefon numarası',
    EMAIL: 'E-posta',
    SHIPPING_ADDRESS: 'Sevkiyat adresi',
    ADDRESS: 'Adres',
    HOUSE_NUMBER: 'Varsa bir ev numarası ekleyin',
    CITY: 'Şehir',
    REGION: 'Bölge',
    POSTAL_CODE: 'Posta Kodu',
    CONTINUE_TO_PAYMENT: 'Ödemeye devam et',
    OPEN_WEBSITE: 'Harici web sitesini yeni bir pencerede açar.',
    OPEN_WINDOW: 'Yeni bir pencerede açılır.',
    OPEN_EXTERNAL_WEBSITE: 'Harici web sitesini açar.',
    CUSTOMER_INFORMATION: 'Müşteri bilgileri - Liva Testi - Ödeme',
    ORDER_SUMMERY: 'Sipariş özeti',
    SHIPPING_CART: 'Alışveriş sepeti',
    PRODUCT_IMAGE: 'Ürün resmi',
    DESCRIPTION: 'Açıklama',
    QUANTITY: 'Miktar',
    PRICE: 'Fiyat',
    SCROLL_FOR_MORE_ITEMS: 'Daha fazla öğe için kaydırın',
    COST_SUMMERY: 'Maliyet özeti',
    TOTAL: 'Toplam',
    PROD_SERVICES_TOTAL: 'ürün/hizmet toplamı',
    UPDATE_TOTAL_PRICE: 'Toplam fiyat güncellendi',
    APARTMENT_SUIT: 'Daire, süit vb. (isteğe bağlı)',
    COUNTRY_REGION: 'Ülke/bölge',
    CHANGE: 'Değiştir',
    CONTACT: 'İletişim',
    SHIP_TO: 'Alıcı',
    PAYMENT: 'Ödeme',
    CHOOSE_PAYMENT_METHOD: 'Bir ödeme yöntemi seçin',
    COMPLETE_PAYMENT: 'Ödemeyi tamamla',
    ALL_TRANSACTIONS_ARE_SECURE: 'Tüm işlemler güvenli ve şifrelidir.',
    FULFILLED_SUCCESSFULLY: 'İstek başarıyla yerine getirildi',
    REQUEST_FAILED: 'Maalesef istek başarısız oldu',
    ERROR: 'Hata',
    SUCCESS: 'Başarılı',
    MIN_LENGTH_ERROR: 'En az {{minlength}} karakter olmalıdır.',
    MAX_LENGTH_ERROR: 'En fazla {{maxlength}} karakter içermelidir.',
    FIELD_REQUIRED: '{{field}} gerekli',
    FIELD_IS_NOT_VALID: '{{field}} geçerli değil',
    SKIP_TO_CONTENT: 'İçeriğe atla',
    INFORMATION: 'Bilgi',
    SALE_PRICE: 'Satış ücreti',
    ALL_RIGHT_RESERVED: 'Tüm hakları Saklıdır',
    CREDIT_CARD: 'Kredi kartı',
    PAY_WITH: 'İle ödeme',
    CASH_ON_DELIVERY: 'Kapıda Ödeme (KÖ)',
    STORE: 'Mağaza',
    SHOW_ORDER_SUMMARY: 'Sipariş özetini göster',
    HIDE_ORDER_SUMMARY: 'Sipariş özetini gizle',
    CARD_NUMBER: 'Kart numarası',
    NAME_ON_CARD: 'Karttaki isim',
    EXPIRATION_DATE: 'Son kullanma tarihi',
    EXPIRATION_DATE_WITH_INPUT_STYLE: 'Son kullanma tarihi (AA / YY)',
    SECURITY_CODE: 'Güvenlik Kodu',
    MONTH: 'Ay',
    YEAR: 'Yıl',
    ERROR_404: 'OOPS! Burada bir şeyler ters gitti',
    ITS_ORDERED: 'Sipariş Edildi!',
    THANKS_FOR_ORDER: 'Teşekkürler, ödemeniz alındı',
    SHOPPING_WITH_US: 'bizimle alışveriş yapıyoruz',
    ORDER_NUMBER: 'Sipariş Numarası',
    PAYMENT_FAILED: 'Ödeme başarısız',
    PAYMENT_FAILED_DESCRIPTION: 'Ödeme yönteminiz reddedildi',
    CODE: 'Kod',
    INVALID_CVC: "Cvc'niz geçersiz",
    INVALID_CVC2: "cvc2'niz geçersiz",
    INSUFFICIENT_FUNDS: 'Paranız işlem için yeterli değil',
    EXPIRED_CARD: 'Kartınızın süresi doldu',
    GENERAL_PAYMENT_ERROR: 'Üzgünüz, ödemeniz tamamlanamadı',
    PAYMENT_NOT_COMPLETED: 'Maalesef ödeme işleminiz tamamlanmadı',
    YOUR_CARD_RESTRICTED:
      'Kartınız çevrimiçi ödemeler için kısıtlanmıştır. Bankanızla iletişime geçerek kartınız için çevrimiçi ödemeleri etkinleştirebilirsiniz',
    MAX_STOCK_EXCEEDED: 'Sepetinizdeki ürünlerden biri stoktaki sayıdan az',
    PAYMENT_CONFIGURATION_NOT_SET:
      'Satıcı panelinde ödeme yapılandırması ayarlanmadı',
    INVALID_TRANSACTION: 'İşleminiz geçersiz',
    OPERATION_NOT_APPROVED: 'İşlem onaylanmadı',
    PAYMENT_REQUEST_HAS_NOT_BEEN_PASSED_FRAUD_CHECK:
      'Ödeme isteği Dolandırıcılık kontrolünden geçmedi',
    OPERATION_NOT_PERMITTED_TO_CARD_HOLDER:
      'Kart sahibine izin verilmeyen işlem',
    OPERATION_NOT_PERMITTED_TO_TERMINAL: 'Terminale izin verilmeyen operasyon',
    FRAUD_SUSPECT: 'dolandırıcılık şüphelisi',
    ERROR_PROCESSING_PAYMENT: 'Ödeme işlenirken bir hata oluştu',
    SYSTEM_ERROR: 'Sistem hatası oluştu',
    COMPLETE_PAYMENT_PROCESS: 'Ödeme işlemini tamamlayın',
    FORM: 'Biçim',
    _3DS_SECURE_IS_NOT_SUPPORTED:
      'Ödeme hizmetine şimdi bağlanılamıyor veya kredi kartınız 3D güvenli desteklenmiyor',
    PROVIDER_IS_NOT_VALID: 'Ödeme sağlayıcıyla ilgili bir sorun var',
    LOCAL_CARDS_ARE_INVALID:
      'Yerel kartlar yabancı para ödemeleri için geçersiz',
    PHONE_IS_INVALID: 'Telefon geçersiz',
    WAIT_TILL_FORM_GET_LOADED:
      'Lütfen banka kartı bilgileri görüntülenene kadar bekleyin',
    PAYMENT_OPTIONS: 'Taksit Secenekleri',
    INSTALLMENT: 'Taksit',
    INSTALLMENT_RATE: 'Taksit Oranı',
    TOTAL_PRICE: 'Toplam fiyat',
    INSTALLMENT_ERROR: 'Üzgünüz, banka kartınız ile taksit yapılamamaktadır.',
    PAYMENT_REQUIRES_MORE_CREDIT:
      'Bu ödeme daha fazla kredi gerektirir, Bu ödemeyi tamamlamak için taksit seçeneğini kullanabilirsiniz.',
    PAYMENT_INFORMATION: 'Ödeme Bilgileri',
    PAYMENT_INFORMATION_DESCRIPTION: 'Tutar ve Müşteri Bilgileri',
    CREDIT_CARD_INFORMATION: 'Kredi Kartı Bilgileri',
    CREDIT_CARD_INFORMATION_DESCRIPTION: 'Kredi Kartı ve Taksit Seçimi',
    PAYMENT_RESULT: 'Ödeme Sonucu',
    PAYMENT_RESULT_DESCRIPTION: 'İşlem Sonucu ve Dekont',
    YOUR_NAME: 'Adınız Soyadınız',
    E_MAIL: 'E-posta Adresiniz',
    GSM_NUMBER: 'GSM Numaranız',
    AMOUNT: 'Tutar',
    PAYMENT_OPTIONS_DESCRIPTION: 'Tutar ve kredi kartı bilgilerini giriniz.',
    NUMBER_OF_INSTALLMENTS: 'Taksit Sayısı',
    INSTALLMENT_AMOUNT: 'Taksit Tutarı',
    THE_TOTAL_PAYMENT: 'Toplam Ödeme',
    INSTALLMENT_PAYMENT: 'Taksitli Ödeme',
    INSTALLMENTS: 'Taksit',
    PAY: 'Ödeme Yap',
    NEXT_STEP: ' Sonraki Adım',
    CVV_SECURITY_CODE: 'CVV Güvenlik Kodu',
    ENTER_SHIPPING_ADDRESS: 'Gönderim adresi ayrıntılarınızı buraya girin',
    CHOOSE: 'Seçmek',
    STORE_HAS_NO_ACTIVE_PAYMENT: 'Bu mağazanın etkin bir ödeme yöntemi yok',
    LOWER_PRICE_ERROR: 'Lütfen fiyatınızı düşürün, maksimum tutarı aştı',
  },
};
