import { Injectable } from '@angular/core';
import localeAF from '@angular/common/locales/af';
import localeAR from '@angular/common/locales/ar';
import localeCA from '@angular/common/locales/ca';
import localeDE from '@angular/common/locales/de';
import localeEL from '@angular/common/locales/el';
import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import localeFA from '@angular/common/locales/fa';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import localeJA from '@angular/common/locales/ja';
import localeNL from '@angular/common/locales/nl';
import localeRU from '@angular/common/locales/ru';
import localeTR from '@angular/common/locales/tr';
import localeUZ from '@angular/common/locales/uz';
import localeZH from '@angular/common/locales/zh';
import { registerLocaleData } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private readonly _supportedLocales = [
    'en',
    'tr',
    'fr',
    'ca',
    'ru',
    'ar',
    'it',
    'de',
    'es',
    'fa',
    'zh',
    'uz',
    'nl',
    'ja',
    'el',
    'af',
  ];
  private readonly _defaultLocales = [
    'en',
    'tr',
    'ca',
    'ru',
    'fr',
    'de',
    'nl',
    'fa',
  ];

  constructor() {}

  public registerDefaultLocales(): void {
    registerLocaleData(localeEN);
    registerLocaleData(localeTR);
    registerLocaleData(localeCA);
    registerLocaleData(localeRU);
    registerLocaleData(localeFR);
    registerLocaleData(localeDE);
    registerLocaleData(localeNL);
    registerLocaleData(localeFA);
  }

  public registerLocale(locale: string = 'en') {
    const requestedLocale = `locale${locale.toUpperCase()}`;
    if (!this._defaultLocales.includes(locale)) {
      const localeFile = this._supportedLocales.includes(locale)
        ? requestedLocale
        : localeEN;
      registerLocaleData(localeFile);
    }
  }
}
