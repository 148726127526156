import { Component } from '@angular/core';
import { LanguageService } from './modules/i18n/languages.service';
import { TranslationService } from './modules/i18n/translation.service';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as trLang } from './modules/i18n/vocabs/tr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  constructor(
    private translationService: TranslationService,
    private languageService: LanguageService
  ) {
    this.translationService.loadTranslations(enLang, trLang, deLang);
    this.languageService.setDefaultLanguage();
  }
}
