import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShopilinkDataBusService {
  public shopilinkData$: Observable<any>;
  public shopilinkDataSubject: BehaviorSubject<any>;

  public shopilinkOrderData$: Observable<any>;
  public shopilinkOrderDataSubject: BehaviorSubject<any>;

  public paymentData$: Observable<any>;
  public paymentDataSubject: BehaviorSubject<any>;

  public orderId$: Observable<string>;
  public orderIdSubject: BehaviorSubject<string>;

  constructor() {
    this.shopilinkDataSubject = new BehaviorSubject<string>('');
    this.shopilinkData$ = this.shopilinkDataSubject.asObservable();

    this.shopilinkOrderDataSubject = new BehaviorSubject<string>('');
    this.shopilinkOrderData$ = this.shopilinkDataSubject.asObservable();

    this.paymentDataSubject = new BehaviorSubject<any>(null);
    this.paymentData$ = this.paymentDataSubject.asObservable();

    this.orderIdSubject = new BehaviorSubject<string>('');
    this.orderId$ = this.orderIdSubject.asObservable();
  }

  public updateShopilinkData(data: any): void {
    this.shopilinkDataSubject.next(data);
  }

  public updateShopilinkOrderData(data: any): void {
    this.shopilinkOrderDataSubject.next(data);
  }

  public updatePaymentData(data: any): void {
    this.paymentDataSubject.next(data);
  }

  public updateOrderId(data: string): void {
    this.paymentDataSubject.next(data);
  }
}
