import { PAYMENT_TYPE } from 'src/app/shared/enums/payment-types.enum';

export interface IOrderByShopilinkModel {
  channelId?: string;
  shopiLinkId?: string;
  userNote?: string;
  bankAccount?: string;
  paymentAccount?: {
    accountAddress: string;
    accountNumber: string;
    accountCode: string;
    accountName: string;
    isActive: true;
    name: string;
    nameSurname: string;
    bankName: string;
  };
  paymentType?: PAYMENT_TYPE;
  shippingAddress?: {
    city: string;
    state: string;
    country: string;
    description: string;
    zipCode: string;
    phoneNumber?: string;
    nameSurname?: string;
  };
  billingAddress?: {
    identityNumber: string;
    companyName: string;
    taxNumber: string;
    taxOffice: string;
    billType: string;
    city: string;
    state: string;
    country: string;
    description: string;
    zipCode: string;
    phoneNumber: string;
    nameSurname: string;
  };
  buyer?: {
    id?: string;
    name: string;
    surname: string;
    phone: string;
    email: string;
    identiyNumber?: string;
  };
  creditCard?: {
    cardHolderName?: string;
    cardNumber?: string;
    expireMonth?: 0;
    expireYear?: 0;
    cvc?: string;
    installment?: string;
  };
  threeDSecure: boolean;
}
