import { environment } from 'src/environments/environment';

export const END_POINTS_URL = {
  V1: {
    ADDRESSES_URL: `${environment.PUBLIC_API}/v1.0/addresses`,
  },
  V2: {
    ORDERS_URL: `${environment.SHOPIROLLER_API}/v2.0/orders`,
    SHOPILINKS: `${environment.SHOPIROLLER_API}/v2.0/shopiLinks`,
    PAYMENT_SETTINGS: `${environment.SHOPIROLLER_API}/v2.0/paymentSettings`,
    COLLECTIONS_URL: `${environment.SHOPIROLLER_API}/v2.0/collections`,
  },
};
