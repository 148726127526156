import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { APIKeyInterceptor } from './core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ContentLayoutComponent } from './shared/layouts/content-layout/content-layout.component';
import { EmptyLayoutComponent } from './shared/layouts/empty-layout/empty-layout.component';
import { LanguageService } from './modules/i18n/languages.service';
import { LocaleService } from './modules/i18n/locale.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AppComponent, EmptyLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    LanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ls: LanguageService) => () => ls.setDefaultLanguage(),
      deps: [LanguageService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LocaleService],
      useFactory: (localService: LocaleService) => {
        return () => {
          return localService.registerDefaultLocales();
        };
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: APIKeyInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
