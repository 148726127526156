import { RouterModule, Routes, UrlSegment } from '@angular/router';

import { EmptyLayoutComponent } from './shared/layouts/empty-layout/empty-layout.component';
import { NgModule } from '@angular/core';
import { ShellComponent } from './modules/easy-collection/features/shell/shell.component';

const routes: Routes = [
  {
    path: 'collection/:slug',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./modules/easy-collection/easy-collection.module').then(
        (m) => m.EasyCollectionModule
      ),
  },
  {
    path: ':slug',
    pathMatch: 'full',
    component: EmptyLayoutComponent,
    loadChildren: () =>
      import('./modules/shop/shop.module').then((m) => m.ShopModule),
  },
  {
    path: 'result',
    loadChildren: () =>
      import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./modules/payment/payment.module').then((c) => c.PaymentModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
