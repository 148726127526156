export const ValidationValues = {
  emailMaxLength: 256,
  phoneNumberRegXPattern: '[- +()0-9]+',
  phoneNumberMaxLength: 18,
  shortTextMinLength: 2,
  shortTextMaxLength: 60,
  middleTextMinLength: 2,
  middleTextMaxLength: 255,
  longTextMaxLength: 1000,
  zipCodeMaxLength: 12,
  cityMinLength: 2,
  cityMaxLength: 90,
  streetMaxLength: 100,
  securityCodeMinLength: 3,
  securityCodeMaxLength: 4,
  cardNumberMaxLength: 16,
  idLength: 24,
};
